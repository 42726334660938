import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "./components/css/Testi.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./components/css/App.css";
import "./components/Latoserv/Fetch/backoffice.css";
import "./components/css/query.css";
import "./118n.js";
import "./components/card/Card1.css";
import "./components/Navbar/navbar.css";
import "./components/css/immagini.css";
import "./components/Footer/Footer.css";
// per integrare uno store Redux all'interno di un'applicazione React

// react-redux fornisce i componenti e gli hooks necessari
// per integrare uno store Redux all'interno di un'applicazione React

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // circondiamo App dal Provider, a cui abbiamo fornito la prop "store"

  <App />
);
