import React from "react";
import { FaFacebookF, FaInstagram, FaGoogle } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Footer2 = () => {
  const { t } = useTranslation();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="footer-dark" id="footerxx">
      <footer>
        <div className="container" style={{ marginTop: "46px" }}>
          <hr></hr>
          <hr></hr>
          <div className="row">
            <div className="col-md-2 item2.text" style={{ textAlign: "center" }}>
              <div className="admin mb-4">
                <Link to="/Admin" className="nav-link" onClick={scrollToTop}>
                  <img
                    width="50"
                    height="50"
                    src="https://img.icons8.com/ios-filled/50/FFFFFF/admin-settings-male.png"
                    alt="admin-settings-male"
                    className="mb-2"
                  />
                </Link>
                <h3 style={{ opacity: 0.6 }}>Log-in</h3>
              </div>
            </div>
            <div className="col-sm-6 col-md-3 item">
              <h3 id="titlefooter">{t("servizi")}</h3>
              <ul>
                <li>
                  <br />
                  <a href="https://www.booking.com/hotel/it/iknos-accommodations.it.html?aid=318615&label=New_Italian_IT_IT_21439071025-UNZN0NGydu%2Ab2jBBcXOAlgS640938613103%3Apl%3Ata%3Ap1%3Ap2%3Aac%3Aap%3Aneg-Share-928BLO%401715595703&sid=24aa4718cfdb629d04a77086e0d204be&dest_id=-131122;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;req_adults=2;req_children=0;room1=A%2CA;sb_price_type=total;sr_order=popularity;srepoch=1715595840;srpvid=4b0e491d5e1d0458;type=total;ucfs=1&">
                    {t("affittacamere")}
                  </a>
                </li>
                <li>
                  <br />
                  <a href="https://www.iknosristo.it/Ristorante">{t("ristorante")}</a>
                </li>
                <li>
                  <br />
                  <a href="https://www.iknosristo.it/Ristorante">{t("pizzeria")}</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-6 col-md-3 item">
              <h3 id="titlefooter">{t("contatti")}</h3>
              <ul>
                <li>
                  <br />
                  <a href="mailto:iknosristo@gmail.com">iknosristo@gmail.com</a>
                </li>
                <li>
                  <br />
                  <a href="tel:0782 450412">0782 450412</a>
                </li>
                <li>
                  <br />
                  <a href="https://maps.app.goo.gl/PwHJ3stBRAugHHni9">Via monsignor Virgilio 42, Tortolì -NU- 08048</a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 item2.text" style={{ textAlign: "center" }}>
              <h3 id="titlefooter">Iknos Restaurant</h3>
              <br />
              <p style={{ opacity: 0.6 }}>"{t("testofooter")}"</p>
            </div>
            <hr></hr>
            <hr></hr>
            <div className="col item social">
              <a
                href="https://www.facebook.com/p/IKNOS-risto-pub-100046797082060/?locale=it_IT"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaFacebookF />
                <span className="sr-only">Facebook</span>
              </a>
              <a
                href="https://www.instagram.com/iknos_ristopub?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaInstagram />
                <span className="sr-only">Instagram</span>
              </a>
              <a href="https://maps.app.goo.gl/PwHJ3stBRAugHHni9" target="_blank" rel="noopener noreferrer">
                <FaGoogle />
                <span className="sr-only">Google Maps</span>
              </a>
            </div>
          </div>

          <a href="https://marcopala-portfolio.web.app/">
            {" "}
            <img src="./white.png" alt="" style={{ width: "118px" }} id="logomio" />
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Footer2;
