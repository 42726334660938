import { Container } from "react-bootstrap";
import Navbar1 from "../Navbar/Navbar2"; // Assuming the file name is Navbar1.js
import "../css/prenota.css";
import Table from "react-bootstrap/Table";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";
function Prenota() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar1 />
      <Container id="container">
        <div className="logocandidati d-flex">
          <img src="/img/iknos2.png" alt="" style={{ width: "138px", marginTop: "34px", marginBottom: "10px" }} />
        </div>
        <div className="paragprenota">
          <br />
          <p className="p9">
            {t("primoprenot")}
            <a href="tel:0782 450412">
              <b>0782 450412</b>
            </a>{" "}
            {t("secondoprenot")}
            <a href="mailto:iknosristo@gmail.com">
              {" "}
              <b>iknosristo@gmail.com</b>
            </a>
            .
          </p>
          <br />
          <hr></hr>
          <p id="orari"> {t("orari")}</p>
          <hr />
          <Table striped="columns" className="mb-5">
            <thead>
              <tr>
                <th> {t("giorno")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> {t("lunedi")}</td>
                <td>12,00am - 23,00pm</td>
              </tr>
              <tr>
                <td> {t("martedi")}</td>
                <td>12,00am - 23,00pm</td>
              </tr>
              <tr>
                <td> {t("mercoledi")}</td>
                <td>12,00am - 23,00pm</td>
              </tr>
              <tr>
                <td> {t("giovedi")}</td>
                <td>12,00am - 23,00pm</td>
              </tr>
              <tr>
                <td> {t("venerdi")}</td>
                <td>12,00am - 23,00pm</td>
              </tr>
              <tr>
                <td> {t("sabato")}</td>
                <td>12,00am - 23,00pm</td>
              </tr>
              <tr>
                <td> {t("domenica")}</td>
                <td>12,00am - 23,00pm</td>
              </tr>
            </tbody>
          </Table>
        </div>
      </Container>
      <Footer></Footer>
    </>
  );
}

export default Prenota;
