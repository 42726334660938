import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav, Container, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../Language/LanguageSelector";

function Navbar1() {
  const [scrolled, setScrolled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [scrollPosition, setScrollPosition] = useState(window.scrollY);
  const [showHamburger, setShowHamburger] = useState(false); // Initially hide hamburger

  const { t } = useTranslation();

  const handleToggle = () => {
    setShowModal(!showModal);
    setShowHamburger(!showModal); // Toggle hamburger visibility
  };

  const handleLinkClick = () => {
    setShowModal(false);
    setShowHamburger(false); // Ensure hamburger is hidden when modal is closed
  };

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Reset hamburger state when modal is closed
    if (!showModal) {
      setShowHamburger(false);
    }
  }, [showModal]);

  return (
    <Navbar expand="lg" className={`custom-navbar ${scrolled ? "scrolled" : ""}`} id="nav">
      <Container fluid style={{ paddingLeft: "30px", paddingRight: "30px" }}>
        <Link
          to="/"
          id="title1"
          className={`nav-link ${scrolled ? "text-black" : "text-black"}`}
          onClick={handleLinkClick}
        >
          <span>IKNOS</span>
        </Link>
        <Navbar.Brand href="#"></Navbar.Brand>
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" style={{ gap: "24px" }} navbarScroll>
            <NavLink exact to="/" className="nav-link text-black" activeClassName="active" onClick={handleLinkClick}>
              {t("navbar1")}
            </NavLink>
            <NavLink
              to="/Ristorante"
              className="nav-link text-black "
              activeClassName="active"
              onClick={handleLinkClick}
            >
              {t("navbar2")}
            </NavLink>
            <NavLink to="/Prenota" className="nav-link text-black" activeClassName="active" onClick={handleLinkClick}>
              {t("navbar3")}
            </NavLink>
            <NavLink to="/Gallery" className="nav-link text-black" activeClassName="active" onClick={handleLinkClick}>
              {t("navbar4")}
            </NavLink>
            <NavLink to="/Candidati" className="nav-link text-black" activeClassName="active" onClick={handleLinkClick}>
              {t("navbar5")}
            </NavLink>
            <LanguageSelector />
          </Nav>
        </Navbar.Collapse>
        <label className={`hamburger ${!showHamburger ? "hidden" : ""}`}>
          <input type="checkbox" checked={showModal} onChange={handleToggle} />
          <svg viewBox="0 0 32 32">
            <path
              className="line line-top-bottom"
              d="M27 10H13C10.8 10 9 8.2 9 6C9 3.5 10.8 2 13 2C15.2 2 17 3.8 17 6V26C17 28.2 18.8 30 21 30C23.2 30 25 28.2 25 26C25 23.8 23.2 22 21 22H7"
            ></path>
            <path className="line" d="M7 16H27"></path>
          </svg>
        </label>
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Menu</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Nav className="flex-column">
            <Link to="/" className="nav-link" onClick={handleLinkClick}>
              {t("navbar1")}
            </Link>
            <Link to="/Ristorante" className="nav-link" onClick={handleLinkClick}>
              {t("navbar2")}
            </Link>
            <Link to="/Prenota" className="nav-link" onClick={handleLinkClick}>
              {t("navbar3")}
            </Link>
            <Link to="/Gallery" className="nav-link" onClick={handleLinkClick}>
              {t("navbar4")}
            </Link>
            <Link to="/Candidati" className="nav-link" onClick={handleLinkClick}>
              {t("navbar5")}
            </Link>
          </Nav>
          <LanguageSelector />
        </Modal.Body>
      </Modal>
    </Navbar>
  );
}

export default Navbar1;
