import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar2 from "../Navbar/Navbar2";
import Footer2 from "../Footer/Footer";
import "../Ristorante/ristorante.css";

function BasicExample() {
  return (
    <>
      <Navbar2 />

      <Container style={{ marginBottom: "100px" }}>
        <div className="logocandidati d-flex">
          <img src="/img/iknos2.png" alt="" style={{ width: "100%" }} />
        </div>
        <hr />
        <img
          src="/img/tessuto-bisaccia-20-greca.jpeg"
          alt="bisaccia"
          style={{
            height: "60px",
            width: "100%",
            objectFit: "cover",
            opacity: "0.1",
            marginBottom: "20px",
          }}
        />
        <Row>
          <Col xs={12} md={6} lg={6} xl={6} id="colo1" className="button-container" style={{ width: "270px" }}>
            <Link to="/AdminBack">
              <Button variant="success" style={{ width: "200px" }}>
                Inserisci una piatto{" "}
              </Button>
            </Link>
          </Col>

          <Col xs={12} md={6} lg={6} xl={6} id="colo1" style={{ width: "270px" }} className="button-container">
            <Link to="/insert">
              <Button variant="success" style={{ width: "250px" }}>
                Inserisci una foto alla galleria
              </Button>
            </Link>
          </Col>
        </Row>

        <img
          src="/img/tessuto-bisaccia-20-greca.jpeg"
          alt="bisaccia"
          style={{
            height: "60px",
            width: "100%",
            objectFit: "cover",
            opacity: "0.1",
            marginTop: "20px",
          }}
        />
        <hr />
      </Container>
      <Footer2 />
    </>
  );
}

export default BasicExample;
