import React, { useState } from "react";
import { Button, Col, Container, Form, InputGroup, Row } from "react-bootstrap";
import * as yup from "yup";
import { Formik } from "formik";
import Navbar2 from "../Navbar/Navbar2";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

function FormExample() {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { t } = useTranslation();

  const schema = yup.object().shape({
    firstName: yup.string().required("Il nome è richiesto"),
    lastName: yup.string().required("Il cognome è richiesto"),
    email: yup.string().required("Il nome utente è richiesto"),
    city: yup.string().required("La città è richiesta"),
    state: yup.string().required("Lo stato è richiesto"),
    cellulare: yup.string().required("Il numero di cellulare è richiesto"),
    ruolo: yup.string().required("Il ruolo è richiesto"),
    file: yup.mixed().required("Il file è richiesto"),
    terms: yup.bool().oneOf([true], "I termini devono essere accettati").required("I termini devono essere accettati"),
  });

  const submitForm = async (values) => {
    try {
      const formData = new FormData();
      formData.append("firstName", values.firstName);
      formData.append("lastName", values.lastName);
      formData.append("email", values.email);
      formData.append("city", values.city);
      formData.append("state", values.state);
      formData.append("cellulare", values.cellulare);
      formData.append("ruolo", values.ruolo);
      formData.append("file", values.file); // Aggiungi il file al FormData

      const response = await fetch("iknos/", {
        method: "POST",
        body: formData, // Passa il FormData come corpo della richiesta
      });

      if (response.ok) {
        setFormSubmitted(true);
      } else {
        console.error("Impossibile inviare il modulo:", response.statusText);
      }
    } catch (error) {
      console.error("Impossibile inviare il modulo:", error);
    }
  };

  return (
    <>
      <Navbar2 />
      <Container>
        {formSubmitted ? (
          <div className="text-center">
            <h3>Modulo Inviato con Successo!</h3>
            <p>Abbiamo ricevuto le tue informazioni.</p>
          </div>
        ) : (
          <div>
            <div className="logocandidati d-flex">
              <img
                src="/img/iknos2.png"
                alt=""
                style={{ width: "108px", marginTop: "34px", marginBottom: "20px" }}
                id="logocandidati"
              />
            </div>
            <Formik
              validationSchema={schema}
              onSubmit={(values) => {
                submitForm(values);
              }}
              initialValues={{
                firstName: "",
                lastName: "",
                email: "",
                city: "",
                state: "",
                cellulare: "",
                ruolo: "",
                file: null,
                terms: false,
              }}
            >
              {({ handleSubmit, handleChange, values, touched, errors, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="4" controlId="validationFormik101" className="position-relative">
                      <Form.Label> {t("nome")}</Form.Label>
                      <Form.Control
                        placeholder={t("pnome")}
                        type="text"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        isValid={touched.firstName && !errors.firstName}
                        isInvalid={touched.firstName && !!errors.firstName}
                        className="mb-3"
                      />
                      <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationFormik102" className="position-relative">
                      <Form.Label> {t("cognome")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("pcognome")}
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        isValid={touched.lastName && !errors.lastName}
                        isInvalid={touched.lastName && !!errors.lastName}
                        className="mb-3"
                      />
                      <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="4" controlId="validationFormikemail2">
                      <Form.Label> {t("email")}</Form.Label>
                      <InputGroup hasValidation>
                        <Form.Control
                          type="text"
                          placeholder={t("pemail")}
                          aria-describedby="inputGroupPrepend"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isValid={touched.email && !errors.email}
                          isInvalid={touched.email && !!errors.email}
                          className="mb-3"
                        />
                        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6" controlId="validationFormik103" className="position-relative">
                      <Form.Label> {t("citta")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("citta")}
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        isValid={touched.city && !errors.city}
                        isInvalid={touched.city && !!errors.city}
                        className="mb-3"
                      />
                      <Form.Control.Feedback type="invalid">{errors.city}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationFormik104" className="position-relative">
                      <Form.Label> {t("stato")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("pstato")}
                        name="state"
                        value={values.state}
                        onChange={handleChange}
                        isValid={touched.state && !errors.state}
                        isInvalid={touched.state && !!errors.state}
                        className="mb-3"
                      />
                      <Form.Control.Feedback type="invalid">{errors.state}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group as={Col} md="3" controlId="validationFormik105" className="position-relative">
                      <Form.Label> {t("cellulare")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("pcellulare")}
                        name="cellulare"
                        value={values.cellulare}
                        onChange={handleChange}
                        isValid={touched.cellulare && !errors.cellulare}
                        isInvalid={touched.cellulare && !!errors.cellulare}
                        className="mb-3"
                      />
                      <Form.Control.Feedback type="invalid">{errors.cellulare}</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row>
                    <Form.Group as={Col} md="4" controlId="validationFormik106" className="position-relative">
                      <Form.Label className="mt-2"> {t("ruolo")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("pruolo")}
                        name="ruolo"
                        value={values.ruolo}
                        onChange={handleChange}
                        isValid={touched.ruolo && !errors.ruolo}
                        isInvalid={touched.ruolo && !!errors.ruolo}
                        className="mb-3"
                      />
                      <Form.Control.Feedback type="invalid">{errors.ruolo}</Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Form.Group className="position-relative mb-3 mt-2">
                    <Form.Label> {t("file")}</Form.Label>
                    <Form.Control
                      type="file"
                      required
                      name="file"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]); // Imposta il valore del campo file
                      }}
                      isInvalid={!!errors.file}
                      className="mb-3"
                    />
                    <Form.Control.Feedback type="invalid">{errors.file}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="position-relative mb-3">
                    <Form.Check
                      required
                      name="terms"
                      label={t("termini")}
                      onChange={handleChange}
                      isInvalid={!!errors.terms}
                      feedback={errors.terms}
                      feedbackType="invalid"
                      id="validationFormik106"
                      feedbackTooltip
                      className="mt-2"
                    />
                  </Form.Group>

                  <br></br>
                  <p style={{ textAlign: "center", fontFamily: "italian" }}>
                    {" "}
                    <b> {t("cv")}</b>
                  </p>

                  <Button
                    type="submit"
                    style={{ display: "flex", marginInline: "auto", marginBottom: "70px" }}
                    id="btncandidati"
                  >
                    {t("invia")}{" "}
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Container>
      <Footer></Footer>
    </>
  );
}

export default FormExample;
