import React, { useState, useEffect } from "react";
import { Container, Card, Col, Row, Modal, Button } from "react-bootstrap";
import LazyLoad from "react-lazyload";
import Navbar1 from "../Navbar/Navbar2";
import Footer2 from "../Footer/Footer";
import "./gallery.css";

function Gallery() {
  const [data, setData] = useState([]);
  const [loadingError, setLoadingError] = useState({ loading: true, error: null });
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("iknos/import.php");
        if (!response.ok) {
          throw new Error("Errore nel caricamento dei dati");
        }
        const jsonData = await response.json();

        const dataWithImageURL = jsonData.map((item) => ({
          ...item,
          image_url: `/iknos/img/${item.file}`,
        }));

        setData(dataWithImageURL);
        setLoadingError({ loading: false, error: null });
      } catch (error) {
        setLoadingError({ loading: false, error: error.message });
      }
    };

    fetchData();
  }, []);

  const handleShowModal = (item) => setSelectedItem(item);
  const handleCloseModal = () => setSelectedItem(null);

  return (
    <>
      <Navbar1 />
      <Container>
        <Row className="mb-5 mt-5" />
        {loadingError.loading && <p></p>}
        {loadingError.error && <p>{loadingError.error}</p>}
        {!loadingError.loading && !loadingError.error && (
          <Row className="mt-5">
            {data.map((item) => (
              <Col key={item.id} xs={12} md={4} lg={3} id="cardmanuale">
                <Card onClick={() => handleShowModal(item)} style={{ border: "none" }} id="cardmanuale">
                  <LazyLoad height={250} offset={100} once>
                    <Card.Img
                      variant="top"
                      src={item.image_url}
                      style={{ height: "250px", objectFit: "cover" }}
                      alt="card"
                    />
                  </LazyLoad>
                  <div className="overlay">
                    <div className="text">Scopri di più</div>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>
        )}
        <Modal show={!!selectedItem} onHide={handleCloseModal} style={{ backgroundColor: "rgba(128, 128, 128, 0.5)" }}>
          <Modal.Header closeButton />
          <Modal.Body>
            {selectedItem && (
              <>
                <h3 style={{ textAlign: "center", fontSize: "18px", fontWeight: "bold" }}>{selectedItem.titolo}</h3>
                <br />
                <p style={{ textAlign: "center" }}>{selectedItem.descrizione}</p>
                <p style={{ textAlign: "center", fontStyle: "italic" }}>{selectedItem.traduzione}</p>
              </>
            )}
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <Button
              variant="secondary"
              onClick={handleCloseModal}
              style={{ fontSize: "0.8rem", padding: "0.375rem 0.75rem" }}
            >
              Chiudi
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
      <Footer2 />
    </>
  );
}

export default Gallery;
