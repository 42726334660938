import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Ristorante from "./components/Ristorante/Ristorante";

import Candidati from "./components/Latoserv/Candidati";
import Fuorimenu from "./components/Latoserv/Fetch/Fuorimenu.jsx";
import Admin from "./components/Latoserv/Admin";
import AdminBack from "./components/Latoserv/AdminBack";
import Home from "./components/Home/Home";
import Gallery from "./components/Gallery/Gallery";
import Insert from "./components/Latoserv/Fetch/Insertplate.jsx";
import Chose183746 from "./components/Home/Chose183746";
import ScrollToTop from "./ScrollToTop.js";
import Prenota from "./components/Prenota/Prenota.jsx";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Ristorante" element={<Ristorante />} />
        <Route path="/Candidati" element={<Candidati />} />
        <Route path="/Fuorimenu" element={<Fuorimenu />} />
        <Route path="/Admin" element={<Admin />} />
        <Route path="/AdminBack" element={<AdminBack />} />
        <Route path="/Insert" element={<Insert />} />
        <Route path="/Gallery" element={<Gallery />} />
        <Route path="/Chose183746" element={<Chose183746 />} />
        <Route path="/Prenota" element={<Prenota />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
