import React, { startTransition, useEffect, useState, Suspense } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "./Language.css";
import italianoIcon from "./flag/italiano.png";
import ingleseIcon from "./flag/inglese.png";
import tedescoIcon from "./flag/tedesco.png";
import franceseIcon from "./flag/francese.png";

const options = [
  { value: "It", icon: italianoIcon, label: "It" },
  { value: "En", icon: ingleseIcon, label: "En" },
  { value: "De", icon: tedescoIcon, label: "De" },
  { value: "Fr", icon: franceseIcon, label: "Fr" },
];

const customStyles = {
  control: (provided) => ({
    ...provided,
    padding: "0px",
    border: "none",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
    cursor: "pointer",
    backgroundColor: "transparent",
  }),
  option: (provided, state) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    backgroundColor: state.isSelected ? "#007bff" : "#fff",
    color: state.isSelected ? "#fff" : "#000",
    padding: "10px",
  }),
  singleValue: (provided) => ({
    ...provided,
    display: "flex",
    alignItems: "center",
    backgroundColor: "transparent",
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
    backgroundColor: "white",
  }),
};

const formatOptionLabel = ({ icon, label }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <img src={icon} alt={label} style={{ width: 20, height: 20, marginRight: 10 }} />
    {label}
  </div>
);

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language || "It");

  useEffect(() => {
    if (!i18n.language || !options.some((option) => option.value === i18n.language)) {
      startTransition(() => {
        i18n.changeLanguage("It").then(() => {
          setCurrentLanguage("It");
        });
      });
    }
  }, [i18n]);

  const handleChange = (selectedOption) => {
    startTransition(() => {
      i18n.changeLanguage(selectedOption.value).then(() => {
        setCurrentLanguage(selectedOption.value);
      });
    });
  };

  const currentOption = options.find((option) => option.value === currentLanguage) || options[0];

  return (
    <div className="language-container">
      <Select
        id="language-select"
        options={options}
        styles={customStyles}
        formatOptionLabel={formatOptionLabel}
        onChange={handleChange}
        isSearchable={false}
        value={currentOption}
        autoFocus
      />
    </div>
  );
};

const LanguageSelectorWrapper = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <LanguageSelector />
  </Suspense>
);

export default LanguageSelectorWrapper;
