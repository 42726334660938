// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "./components/Language/locales/en/translation.json";
import translationIT from "./components/Language/locales/it/translation.json";
import translationDU from "./components/Language/locales/de/translation.json";
import translationFR from "./components/Language/locales/fr/translation.json";

const resources = {
  En: {
    translation: translationEN,
  },
  It: {
    translation: translationIT,
  },
  De: {
    translation: translationDU,
  },
  Fr: {
    translation: translationFR,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "It", // default language
  fallbackLng: "It",
  interpolation: {
    escapeValue: false, // react already safeguards against XSS
  },
});

export default i18n;
