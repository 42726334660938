import React, { useState, useEffect } from "react";

import * as yup from "yup";
import { Container, ListGroup } from "react-bootstrap";

import Navbar1 from "../../Navbar/Navbar2";

function Backoffice() {
  const [submittedData, setSubmittedData] = useState(null);
  const [menuData, setMenuData] = useState([]);

  const schema = yup.object().shape({
    nome: yup.string().required(),
    prezzo: yup.string().required(),
    descrizione: yup.string().required(),
    tipoPiatto: yup.string().required(),
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch("iknos/importfuorimenu.php");
        if (response.ok) {
          const data = await response.json();

          setMenuData(data);
        } else {
          console.error("Errore durante il recupero dei dati dal database:", response.statusText);
        }
      } catch (error) {
        console.error("Errore durante la comunicazione con il server:", error);
      }
    }

    fetchData();
  }, []);

  const handleSubmit = async (values, actions) => {
    try {
      const formData = new FormData();
      formData.append("nome", values.nome);
      formData.append("prezzo", values.prezzo);
      formData.append("traduzione", values.traduzione);
      formData.append("tipoPiatto", values.tipoPiatto);

      const response = await fetch("http://localhost/iknos/fuorimenu.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Dati inseriti con successo:", data);
        setSubmittedData(data);
        actions.resetForm();
      } else {
        console.error("Errore durante l'inserimento dei dati:", response.statusText);
      }
    } catch (error) {
      console.error("Errore durante la comunicazione con il server:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`http://localhost/iknos/importfuorimenu.php?id=${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const newData = menuData.filter((item) => item.id !== id);
        setMenuData(newData);
        console.log("Riga eliminata con successo");
      } else {
        console.error("Errore durante l'eliminazione della riga:", response.statusText);
      }
    } catch (error) {
      console.error("Errore durante la comunicazione con il server:", error);
    }
  };

  return (
    <div>
      <Navbar1 />

      <Container className="mt-5">
        <h1 className="mt-5 mb-5 text-center">Fuori Menù</h1>
        <ListGroup style={{ maxWidth: "500px", marginInline: "auto", textAlign: "center" }}>
          {/* Render menu data with delete buttons */}
          <ListGroup.Item>
            <h3 className="mb-3 " id="sfondotext">
              Antipasti
            </h3>
            {menuData
              .filter((item) => item.tipoPiatto === "antipasto")
              .map((item, index) => (
                <div key={index} className="mb-3" id="box40">
                  <hr></hr>
                  <p className="pplate" style={{ marginBottom: "0px", whiteSpace: "pre-wrap" }}>
                    {item.nome}
                  </p>
                  <p
                    className="translate"
                    style={{
                      fontFamily: '"Cormorant Garamond", serif',
                      fontWeight: 300,
                      fontStyle: "italic",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {item.traduzione}
                  </p>
                  <p className="pplate" style={{ alignItems: "right" }}>
                    {" "}
                    {item.prezzo + "€"}
                  </p>{" "}
                  <br />
                </div>
              ))}
          </ListGroup.Item>
          <ListGroup.Item>
            <h3 className="mb-3 " id="sfondotext">
              Primi
            </h3>
            {menuData
              .filter((item) => item.tipoPiatto === "primo")
              .map((item, index) => (
                <div key={index} className="mb-3" id="box40">
                  <hr></hr>
                  <p className="pplate">{item.nome}</p>
                  <p
                    className="translate"
                    style={{
                      fontFamily: '"Cormorant Garamond", serif',
                      fontWeight: 300,
                      fontStyle: "italic",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {item.traduzione}
                  </p>
                  <p className="pplate"> {item.prezzo + "€"} </p> <br />
                </div>
              ))}
          </ListGroup.Item>
          <ListGroup.Item>
            <h3 className="mb-3" id="sfondotext">
              Secondi
            </h3>
            {menuData
              .filter((item) => item.tipoPiatto === "secondo")
              .map((item, index) => (
                <div key={index} className="mb-3 " id="box40">
                  <hr></hr>
                  <p className="pplate" style={{ whiteSpace: "pre-wrap" }}>
                    {" "}
                    {item.nome}
                  </p>{" "}
                  <p
                    className="translate"
                    style={{
                      fontFamily: '"Cormorant Garamond", serif',
                      fontWeight: 300,
                      fontStyle: "italic",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    {item.traduzione}
                  </p>
                  <p className="pplate">{item.prezzo + "€"}</p> <br />
                </div>
              ))}
          </ListGroup.Item>
          <ListGroup.Item style={{ marginBottom: "60px" }}>
            <h3 className="mb-3 " id="sfondotext">
              Dolci
            </h3>
            {menuData
              .filter((item) => item.tipoPiatto === "dolce")
              .map((item, index) => (
                <div key={index} className="mb-3" id="box40">
                  <hr></hr>
                  <p className="pplate">{item.nome}</p>
                  <p
                    className="translate"
                    style={{ fontFamily: '"Cormorant Garamond", serif', fontWeight: 300, fontStyle: "italic" }}
                  >
                    {item.traduzione}
                  </p>
                  <p>{item.prezzo + "€"}</p> <br />
                </div>
              ))}
          </ListGroup.Item>
        </ListGroup>
      </Container>
    </div>
  );
}

export default Backoffice;
