import React from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Navbar2 from "../Navbar/Navbar2";
import ristorante from "./Menurist.pdf";
import pizzeria from "./Menupizz.pdf";

import { Row, Col } from "react-bootstrap";
import "./ristorante.css";
import Footer2 from "../Footer/Footer";
import { useTranslation } from "react-i18next";

function BasicExample() {
  const { t } = useTranslation();
  return (
    <>
      <Navbar2 />

      <Container style={{ marginBottom: "100px" }}>
        <div className="logocandidati d-flex">
          <img src="/img/iknos2.png" alt="" id="logorist" />
        </div>
        {/*  <img
          src="/img/tessuto-bisaccia-20-greca.jpeg"
          alt="bisaccia"
          style={{
            height: "20px",
            width: "100%",
            objectFit: "cover",
            opacity: "0.1",
            marginTop: "20px",
          }}
        /> */}
        <hr />
        <Row style={{ marginTop: "30px", marginBottom: "30px" }}>
          <Col xs={4} m={4} l={4} xl={4} id="colo1" className="button-container1">
            <Button
              as="a"
              href={ristorante}
              target="_blank"
              rel="noreferrer"
              variant="primary2"
              style={{ width: "180px" }}
              id="titlerest"
            >
              {t("menu1")}
            </Button>
          </Col>
          <Col xs={4} m={4} l={4} xl={4} id="colo2" className="button-container2">
            <Button
              as="a"
              href={pizzeria}
              target="_blank"
              rel="noreferrer"
              variant="primary2"
              style={{ width: "180px" }}
              id="titlerest"
            >
              {t("menu2")}
            </Button>
          </Col>
          <Col xs={4} m={4} l={4} xl={4} id="colo3" className="button-container3">
            <Link to="/Fuorimenu" style={{ textDecoration: "none", color: "inherit" }}>
              <Button
                variant="primary2"
                id="titlerest"
                style={{ width: "180px", textDecoration: "none", color: "inherit" }}
              >
                {t("menu3")}
              </Button>
            </Link>
          </Col>
        </Row>
        <hr />{" "}
      </Container>
      <Footer2></Footer2>
    </>
  );
}

export default BasicExample;
