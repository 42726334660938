import React, { useEffect, useCallback } from "react";
import { Element } from "react-scroll";
import { Container, Row, Col } from "react-bootstrap";
import { lazy } from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useTranslation } from "react-i18next";

// Lazy load dei componenti
const Video = lazy(() => import("../Video/Video"));
const Card1 = lazy(() => import("../card/Card"));
const CustomNavbar = lazy(() => import("../Navbar/Navbar"));
const Footer = lazy(() => import("../Footer/Footer"));
const HomePage = lazy(() => import("./cartina"));

const animationVariants = {
  fadeLeft: {
    hidden: { opacity: 0, x: -250 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  },
  fadeRight: {
    hidden: { opacity: 0, x: 250 },
    visible: { opacity: 1, x: 0, transition: { duration: 1 } },
  },
  slideInFromBottom: {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1, ease: "easeOut" } },
  },
};

const cardItems = [
  { id: 1, title: "Card 1", content: "Content for card 1" },
  { id: 2, title: "Card 2", content: "Content for card 2" },
  { id: 3, title: "Card 3", content: "Content for card 3" },
];

const Home = () => {
  const { ref: firstParaRef, inView: firstParaInView } = useInView({ triggerOnce: true });
  const { ref: secondParaRef, inView: secondParaInView } = useInView({ triggerOnce: true });

  const { t } = useTranslation();

  const handleClick = useCallback((e) => {
    e.preventDefault();
    const targetElement = document.getElementById("testo1");
    if (targetElement) {
      const targetOffset = targetElement.offsetTop;
      const offset = -260;
      window.scrollTo({
        top: targetOffset + offset,
        behavior: "smooth",
      });
    }
  }, []);

  const handleScroll = useCallback(() => {
    const windowHeight = window.innerHeight;
    const elements = document.querySelectorAll(".animate-on-scroll");

    elements.forEach((element) => {
      const rect = element.getBoundingClientRect();
      const offset = rect.top + rect.height * 0.3;

      if (offset < windowHeight) {
        element.classList.add("fade-in");
      }
    });
  }, []);

  useEffect(() => {
    handleScroll();

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  return (
    <div>
      <CustomNavbar />
      <Video />
      <Element name="section1" className="element" />
      {/* <div onClick={handleClick} style={{ cursor: "pointer" }}>
        <img width="40" height="40" src="./img/arrowdown.gif" alt="double-down" id="arrowdown" />
      </div> */}
      <img src="/img/iknos2.png" alt="" id="iknos" />
      <div id="testo1" className="testo1 animate-on-scroll" style={{ textAlign: "center" }}>
        <motion.p
          ref={firstParaRef}
          variants={animationVariants.fadeRight}
          initial="hidden"
          animate={firstParaInView ? "visible" : "hidden"}
          transition={{ duration: 0.5 }}
          style={{ textAlign: "left", fontSize: "18px" }}
          className="paragrafo"
        >
          <h2 id="titlefirst" style={{ textAlign: "center" }}>
            {t("title")}
          </h2>
          <p id="firstpara">
            {t("description")}
            <br />
            <br />
            <img src="/img/pavoncella.png" alt="" style={{ width: "90px", marginBottom: "25px" }} />
          </p>
          <Card1 items={cardItems} className="animate-on-scroll" />
        </motion.p>
      </div>
      <div id="bisaccia" className="animate-on-scroll">
        <img
          src="/img/tessuto-bisaccia-20-greca.jpeg"
          alt=""
          style={{
            height: "60px",
            width: "100%",
            objectFit: "cover",
            opacity: "0.1",
            marginBottom: "100px",
          }}
        />
      </div>
      <Container>
        <Row style={{ gap: "20px", justifyContent: "center" }}>
          <Col xs={4} m={4} l={4} xl={4} id="colo">
            <div id="icone1">
              <img
                src="/img/icone/icons8-cocktail-48.png"
                alt="Air Conditioner Icon"
                style={{ width: "50px", height: "50px", marginBottom: "20px" }}
              />
              <h4 id="letrecard">{t("primoboxtitle")}</h4>
              <hr />
              <p className="letrecard">{t("primop")}</p>
            </div>
          </Col>
          <Col xs={4} m={4} l={4} xl={4} id="colo">
            <div id="icone1">
              <img
                src="/img/icone/icons8-scintillante-48.png"
                alt="Air Conditioner Icon"
                style={{ width: "50px", height: "50px", marginBottom: "20px" }}
              />
              <h4 id="letrecard">{t("secondoboxtitle")}</h4>
              <hr />
              <p className="letrecard">{t("secondop")}</p>
            </div>
          </Col>
          <Col xs={4} m={4} l={4} xl={4} id="colo">
            <div id="icone1">
              <img
                src="/img/icone/senza.png"
                alt="Air Conditioner Icon"
                style={{ width: "50px", height: "50px", marginBottom: "20px" }}
              />
              <h4 id="letrecard">{t("terzoboxtitle")}</h4>
              <hr />
              <p className="letrecard">{t("terzop")}</p>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <div id="bisaccia" className="animate-on-scroll">
          <img
            src="/img/tessuto-bisaccia-20-greca.jpeg"
            alt=""
            style={{
              height: "60px",
              width: "100%",
              objectFit: "cover",
              opacity: "0.1",
              marginBottom: "59px",
              marginTop: "100px",
            }}
          />
        </div>
        <div className="box14">
          <h2 id="iknosaccom" style={{ textAlign: "center" }}>
            <img src="/img/accomoda.png" alt="" id="iknosaccomimg" />
          </h2>
          <p id="iknoasaccomodation">
            {t("accomodation")}
            <a href="https://www.booking.com/Share-928BLO">{t("vai")}</a>
          </p>
        </div>
        <br />
        <Row className="d-flex justify-content-center" style={{ gap: "20px" }}>
          <Col xs={12} md={4} lg={2} className="img-container" id="fotocamere">
            <img src="/img/affittacamere/caffe.jpeg" alt="" style={{ width: "100%", borderRadius: "6px" }} />
            <div className="hover-text">Coffe room</div>
          </Col>
          <Col xs={12} s={2} md={4} lg={2} className="img-container" id="fotocamere">
            <img src="/img/affittacamere/campari.jpeg" alt="" style={{ width: "100%", borderRadius: "6px" }} />
            <div className="hover-text">Campari room</div>
          </Col>
          <Col xs={12} s={2} md={4} lg={2} className="img-container" id="fotocamere">
            <img src="/img/affittacamere/fiat.jpeg" alt="" style={{ width: "100%", borderRadius: "6px" }} />
            <div className="hover-text">Fiat room</div>
          </Col>
          <Col xs={12} s={2} md={4} lg={2} className="img-container" id="fotocamere">
            <img src="/img/affittacamere/film.jpeg" alt="" style={{ width: "100%", borderRadius: "6px" }} />
            <div className="hover-text">Cinema room</div>
          </Col>
          <Col xs={12} s={2} md={4} lg={2} className="img-container" id="fotocamere">
            <img src="/img/affittacamere/pastajpeg.jpeg" alt="" style={{ width: "100%", borderRadius: "6px" }} />
            <div className="hover-text">Pasta room</div>
          </Col>
        </Row>
      </Container>
      <br />
      <hr />
      <div
        id="logocentrale1"
        style={{ display: "flex", justifyContent: "center", width: "100px", marginInline: "auto" }}
      >
        <img src="/img/iknos2.png" alt="" id="logocentrale" style={{ width: "100px" }} />
      </div>
      <motion.p
        ref={secondParaRef}
        variants={animationVariants.slideInFromBottom}
        initial="hidden"
        animate={secondParaInView ? "visible" : "hidden"}
        transition={{ duration: 0.5 }}
        style={{ textAlign: "left", fontSize: "18px" }}
        className="paragrafo"
      >
        <Container>
          <Row id="discover1" className="animate-on-scroll">
            <Col className="col-12">
              <div className="titolo7">
                <h4
                  className="mt-5"
                  style={{
                    textAlign: "center",
                    color: "grey",
                    fontFamily: "cinzel",
                    letterSpacing: "6px",
                    fontSize: "33px",
                  }}
                  id="titolo7"
                >
                  {t("scopri")}
                </h4>
              </div>
            </Col>
          </Row>
          <Row id="discover" className="animate-on-scroll">
            <Col className="col-12 col-md-6" id="colonna">
              <div className="rett1">
                <div className="rett2">
                  <img
                    src="/img/piatti/carborid.jpg"
                    alt=""
                    style={{
                      width: "100%",
                      borderRadius: "6px",
                      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.9), 0 12px 40px rgba(0, 0, 0, 0.2)",
                      transform: "perspective(1000px) rotateY(23deg)",
                    }}
                  />
                </div>
                <h3 className=" mb-3" id="lastitle">
                  Iknos Restaurant
                </h3>
                <p className="p1">{t("lasttitleleft")}</p>
              </div>
            </Col>
            <Col className="col-12 col-md-6" id="colonna">
              <div className="rett1">
                <img
                  src="/img/affittacamere/campari2.jpeg"
                  alt=""
                  style={{
                    width: "100%",
                    borderRadius: "6px",
                    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.9), 0 12px 40px rgba(0, 0, 0, 0.2)",
                    transform: "perspective(1000px) rotateY(159deg)",
                  }}
                  id="lastimg"
                  className="lastimg"
                />
                <h3 className=" mb-3" id="lastitle">
                  Iknos Accommodation
                </h3>
                <span className="p1">
                  {t("lasttitleright")}
                  <a href="https://www.booking.com/Share-928BLO">{t("vai")}</a>
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </motion.p>
      <HomePage />
      <Footer />
    </div>
  );
};

export default React.memo(Home);
