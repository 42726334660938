import React, { useState } from "react";
import Navbar2 from "../Navbar/Navbar2";
import Footer2 from "../Footer/Footer";

function AuthComponent() {
  const [mode, setMode] = useState("login");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const logout = async (e) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    await fetch("iknos/logout.php", requestOptions);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccessMessage("");

    try {
      let response;
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ action: mode, username, password }),
      };

      response = await fetch("iknos/login.php", requestOptions);
      const data = await response.json();

      if (data.error) {
        setError(data.error);
      } else {
        setSuccessMessage(data.success ? "Success!" : "");

        window.location.href = "/Chose183746"; // Cambia "/dashboard" con la tua rotta desiderata
      }
    } catch (error) {
      setError("Errore durante la comunicazione con il server.");
    }
  };

  return (
    <>
      <Navbar2></Navbar2>

      <div className="auth-container mt-5">
        <h1 className="auth-title mt-4 mb-4" style={{ textAlign: "center" }}>
          {mode === "login" ? "Login" : "Registrazione"}
        </h1>
        <form className="auth-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              id="username"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="form-control"
              placeholder="Inserisci e-mail o usurname"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Inserisci password"
            />
          </div>
          <button id="btn1" type="submit" className="btn btn-primary mt-4">
            {mode === "login" ? "Login" : "Registrati"}
          </button>
        </form>

        {error && <div className="error-message">{error}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}

        <button className="btn btn-primary mt-3 mb-3" onClick={logout} id="btn2">
          {" "}
          log-out
        </button>
      </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <Footer2></Footer2>
    </>
  );
}

export default AuthComponent;
